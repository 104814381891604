import store from '@/store'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/account/login')
  },
  {
    path: '/kickOff/:companyId/:userId',
    name: 'kick-off',
    component: () => import('@/views/account/kick-off')
  },

  /* Companies -------------------------------------------------- */
  {
    path: '/',
    component: () => import('@/layouts/private-layout'),
    redirect: 'companies',
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/companies/dashboard'),
        name: 'dashboard',
        meta: {
          onlySuperAdministrator: true,
          requireCompanySelected: true,
          requireUploadSignature: true,
          validatePayment: true
        }
      },

      /* Companies -------------------------------------------------- */
      {
        path: 'companies',
        component: () => import('@/views/companies'),
        name: 'company-list',
        meta: {
          onlySuperAdministrator: true,
          validatePayment: true
        }
      },
      {
        path: 'home',
        component: () => import('@/views/companies/home'),
        name: 'company-home',
        meta: {
          onlySuperAdministrator: true,
          validatePayment: true
        }
      },
      {
        path: 'api',
        component: () => import('@/views/companies/api'),
        name: 'company-api',
        meta: {
          onlySuperAdministrator: true,
          requireCompanySelected: true,
          requireUploadSignature: true,
          validatePayment: true
        }
      },
      {
        path: 'webhook',
        component: () => import('@/views/companies/webhook'),
        name: 'webhook',
        meta: {
          onlySuperAdministrator: true,
          requireCompanySelected: true,
          requireUploadSignature: true,
          validatePayment: true
        }
      },
      {
        path: 'vouchers/:id',
        component: () => import('@/views/companies/voucher'),
        name: 'hub-voucher',
        meta: {
          onlySuperAdministrator: true,
          requireCompanySelected: store.getters.tokenApp && store.getters.tokenApp.cmpMdBill && store.getters.tokenApp.cmpMdBill.toLowerCase() !== 'DemandaGlobal' && store.getters.tokenApp.cmpMdBill.toLowerCase() !== 'Demanda' && store.getters.tokenApp.cmpMdBill.toLowerCase() !== 'PaqueteGlobal',
          requireUploadSignature: false,
          validatePayment: true
        }
      },
      {
        path: 'logo',
        component: () => import('@/views/companies/logo'),
        name: 'company-logo',
        meta: {
          onlySuperAdministrator: true,
          requireCompanySelected: true,
          requireUploadSignature: true,
          validatePayment: true
        }
      },
      {
        path: 'firm',
        component: () => import('@/views/companies/signature'),
        name: 'company-firm',
        meta: {
          onlySuperAdministrator: true,
          requireCompanySelected: true,
          requireUploadSignature: true,
          validatePayment: true
        }
      },
      {
        path: 'company/add',
        component: () => import('@/views/companies/add'),
        name: 'company-add',
        meta: {
          onlySuperAdministrator: true,
          validatePayment: false
        }
      },
      {
        path: 'update',
        component: () => import('@/views/companies/edit'),
        name: 'company-edit',
        meta: {
          onlySuperAdministrator: true,
          requireCompanySelected: true,
          requireUploadSignature: true,
          validatePayment: false
        }
      },
      {
        path: 'operation-mode',
        component: () => import('@/views/companies/operation-mode'),
        name: 'operation-mode',
        meta: {
          onlySuperAdministrator: true,
          requireCompanySelected: true,
          requireUploadSignature: true,
          validatePayment: true
        }
      },

      /* End of Companies -------------------------------------------------- */
      /* Documents -------------------------------------------------- */

      {
        path: 'log',
        component: () => import('@/views/documents/log'),
        name: 'documents-log',
        meta: {
          onlySuperAdministrator: true,
          requireCompanySelected: true,
          requireUploadSignature: true,
          validatePayment: true
        }
      },
      {
        path: 'import-invoice',
        component: () => import('@/views/documents/import-invoice'),
        name: 'documents-import-invoice',
        meta: {
          onlySuperAdministrator: true,
          requireCompanySelected: true,
          requireUploadSignature: true,
          validatePayment: true
        }
      },
      {
        path: 'import-invoice-note-db',
        component: () => import('@/views/documents/import-invoice-note-db'),
        name: 'documents-import-invoice-note-db',
        meta: {
          onlySuperAdministrator: true,
          requireCompanySelected: true,
          requireUploadSignature: true,
          validatePayment: true
        }
      },
      {
        path: 'import-invoice-note-cr',
        component: () => import('@/views/documents/import-invoice-note-cr'),
        name: 'documents-import-invoice-note-cr',
        meta: {
          onlySuperAdministrator: true,
          requireCompanySelected: true,
          requireUploadSignature: true,
          validatePayment: true
        }
      },
      {
        path: 'users',
        component: () => import('@/views/users/index'),
        name: 'user-list',
        meta: {
          onlySuperAdministrator: true,
          requireCompanySelected: true,
          requireUploadSignature: true,
          validatePayment: true
        }
      },
      {
        path: 'user',
        component: () => import('@/views/users/add'),
        name: 'user-add',
        meta: {
          onlySuperAdministrator: true,
          requireCompanySelected: true,
          requireUploadSignature: true,
          validatePayment: true
        }
      },
      {
        path: 'user/:id',
        component: () => import('@/views/users/add'),
        name: 'user-edit',
        meta: {
          onlySuperAdministrator: true,
          requireCompanySelected: true,
          requireUploadSignature: true,
          validatePayment: true
        }
      },

      /* End of Documents -------------------------------------------------- */
      /* Resolutions
            -------------------------------------------------- */

      {
        path: 'resolutions',
        component: () => import('@/views/resolutions'),
        name: 'resolutions',
        meta: {
          onlySuperAdministrator: true,
          requireCompanySelected: true,
          requireUploadSignature: true,
          validatePayment: true
        }
      }

      /* End of Resolutions
            -------------------------------------------------- */
    ]
  },
  {
    path: '/users',
    component: () => import('@/layouts/private-layout'),
    redirect: 'users',
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'change-password',
        name: 'change-password',
        component: () => import('@/views/users/change-password'),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },

  /* End of Companies -------------------------------------------------- */
  /* Singup -------------------------------------------------- */
  {
    path: '/invoices/preview/:id/:companyId',
    name: 'invoice-preview',
    component: () => import('@/views/invoices/preview'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/invoiceNotesDb/preview/:id/:companyId',
    name: 'invoice-notes-db-preview',
    component: () => import('@/views/invoice-notes-db/preview'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/invoiceNotesCr/preview/:id/:companyId',
    name: 'invoice-notes-cr-preview',
    component: () => import('@/views/invoice-notes-cr/preview'),
    meta: {
      requiresAuth: false
    }
  },

  /* End of Singup -------------------------------------------------- */
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404'),
    meta: {
      requiresAuth: false
    }
  }
]

export default routes
