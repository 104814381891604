export { camelCase as toCamelCase } from 'lodash'

export function toPascalCase(value) {
  return (
    value
      .toString()
      .substring(0, 1)
      .toUpperCase() + value.substring(1)
  )
}

export function toKebabCase(value) {
  return value
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase()
}

export function latinize(value) {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
}
