<template>
    <div class="container">
        <div
            v-for="(itemPlan, index) in data"
            :key="index"
        >
            <div
                class="col col-select"
                @click="onClickPlan(itemPlan)"
            >
                <div class="card">
                    <div class="field">
                        <b-radio
                            :value="value"
                            :native-value="itemPlan.id"
                            type="is-info"
                            size="is-medium"
                            @click="onClickPlan(itemPlan)"
                        >
                        </b-radio>
                    </div>
                    <header class="card-header">
                        <h3 class="title">
{{ itemPlan.planName }}
                            ({{ $tc('active') }})
</h3>
                        <span class="price">
                            {{ itemPlan.planPriceYearly | formatToCurrencyWithIsoCode }}
                        </span>
                        <span
                            class="description"
>{{ itemPlan.planDescription }}</span>
                        <div class="line"></div>
                    </header>
                    <table
                        class="features"
                        width="100%"
                    >
                        <tbody>
                            <tr
                                v-for="(itemFeature, indexFeature) in getFeatures(itemPlan.features)"
                                :key="indexFeature"
                            >
                                <td align="center">
                                    <span
                                        v-if="itemFeature.featureType === 'access' && itemFeature.featureValue === 'true'"
                                        class="tag success"
                                    >
                                        <i class="fad fa-check fa-lg"></i>
                                    </span>
                                    <span
                                        v-if="itemFeature.featureType === 'access' && itemFeature.featureValue === 'false'"
                                        class="tag danger"
                                    >
                                        <i class="fad fa-times fa-lg"></i>
                                    </span>
                                    <span
                                        v-if="itemFeature.featureType !== 'access' && itemFeature.featureValue > 0"
                                        class="tag success"
                                    >
                                        <small>{{ itemFeature.featureValue }}</small>
                                    </span>
                                    <span
                                        v-if="itemFeature.featureType !== 'access' && itemFeature.featureValue < 0"
                                        class="tag primary "
                                    >
                                        {{ $tc('unlimited') }}
                                    </span>
                                    <span
                                        v-if="+itemFeature.featureValue === 0"
                                        class="tag danger"
                                    >
                                        <i class="fad fa-times fa-lg"></i>
                                    </span>
                                </td>
                                <td>
{{ itemFeature.featureName }}
                                    <br>
                                    <span
                                        v-if="itemFeature.featureType !== 'access' && +itemFeature.featureUnitPriceCustom > 0"
                                        style="font-size:10px"
                                    >
                                        <em>{{ itemFeature.featureUnitPriceCustom | formatToCurrencyWithIsoCode }}</em>
                                        &nbsp;
                                        <em>({{ $tc('aditional', 4) }})</em>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    modeBilling: {
      type: String,
      default: 'PaqueteIndividual',
    },
  },
  data() {
    return {
      options: [],
    }
  },
  watch: {
    modeBilling(newValue) {
      if (newValue) {
        this.getData()
      }
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    onClickPlan(plan) {
      this.value = plan.id
      this.$emit('set-selected', plan)
      this.$emit('input', value)
      this.$emit('change', value)
    },
    getFeatures(features) {
      if (features && +features.length) {
        return features
          .filter(item => !(+item.featureValue === 0))
          .sort((a, b) => {
            const valueA =
              a.featureValue === 'true'
                ? 1
                : a.featureValue === 'false'
                  ? -1
                  : +a.featureValue < 0
                    ? 1000000000
                    : +a.featureValue
            const valueB =
              b.featureValue === 'false'
                ? -1
                : b.featureValue === 'true'
                  ? 1
                  : +b.featureValue < 0
                    ? 1000000000
                    : +b.featureValue

            return valueB - valueA
          })
      }

      return []
    },
    getData() {
      const _this = this
      let mode = 'Paquete'
      if (['DemandaIndividual'].includes(_this.modeBilling)) {
        mode = 'Demanda'
      }
      _this.get('', {
        uri: `${process.env.VUE_APP_API_HUB}plans/search`,
        queryStringParams: {
          serviceId: process.env.VUE_APP_SERVICE_FE_ISV_ID,
          planOnSite: true,
          planEnabled: true,
          planMode: mode,
        },
      })
    },
  },
}
</script>
